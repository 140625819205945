<template lang="">
    <div>
        <Detail />
    </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    name:"customer_detail",
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Müşteri Detay" },
      ]);
    },
   components:{
    Detail : () =>import("@/components/customer/Detail")
   }

}
</script>
<style lang="">
    
</style>